<style lang="less">
.login {
  height: 100vh;
  text-align: center;
  .form {
    position: absolute;
    width: 384px;
    height: 400px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    .title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .loginBtn {
      width: 100%;
    }
  }
}
</style>
<template>
  <div class="login">
    <div class="form" @keydown="keyDown">
      <div class="title">{{ title }}</div>
      <Form ref="form" :model="form" :rules="formRules">
        <FormItem prop="account">
          <Input type="text" size="large" v-model="form.account" placeholder="请输入账号">
          <Icon type="ios-person-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem prop="password">
          <Input type="password" size="large" v-model="form.password" placeholder="请输入密码">
          <Icon type="ios-lock-outline" slot="prepend"></Icon>
          </Input>
        </FormItem>
        <FormItem>
          <Button type="primary" size="large" class="loginBtn" @click="login()">登录</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: this.MyUtil.title,
      form: {
        account: "",
        password: "",
      },
      formRules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  methods: {
    login() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.type = this.type;
          this.MyUtil.ajax()
            .post("/UserController/webLogin", this.form)
            .then((resData) => {
              localStorage.clear();
              this.MyUtil.setToken(resData.token);
              this.setKey("userName", resData.name);
              this.$router.push({
                name: "index",
              });
            });
        }
      });
    },
    keyDown(event) {
      if (event.keyCode == 13) {
        this.login();
      }
    },
  },
  created() {},
};
</script>
